import React from 'react';
import axios from 'axios';
import {queryCache} from "react-query";
import $ from 'jquery';
import "./NoteSummary.css";

const NoteSummary = ({title, text, _id, setEditRequiredNote}) => {
    const deleteNote = async e => {
        e.preventDefault();
        $($(e.target).closest('.card').get(0)).css({
            opacity: 0.5
        })
        try {
            await axios.delete(`https://boiling-basin-70629.herokuapp.com/api/notes/${_id}`);
            await queryCache.invalidateQueries('notes');
        } catch (e) {
            console.log(e.message)
        }
    };
    return (
        <li>
            <div className='card'>
                <h3 className="title">{title}</h3>
                <p className="text">{text}</p>
                <div className="button-sec">
                    <button onClick={deleteNote}>
                        <span className="icon delete-note">
                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="trash-alt"
                                 className="svg-inline--fa fa-trash-alt fa-w-14" role="img"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path fill="currentColor"
                                      d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z">
                                </path>
                            </svg>
                        </span>
                    </button>
                    <button onClick={() => setEditRequiredNote(_id)}>
                        <span className="icon edit-note">
                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="edit"
                                 className="svg-inline--fa fa-edit fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 576 512">
                                <path fill="currentColor"
                                      d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z">
                                </path>
                            </svg>
                        </span>
                    </button>
                    {/*{window.innerWidth <= 576 && (*/}
                    {/*    <>*/}
                    {/*        <button onClick={deleteNote}>*/}
                    {/*            Delete*/}
                    {/*        </button>*/}
                    {/*        <button onClick={() => setEditRequiredNote(_id)}>*/}
                    {/*            Edit*/}
                    {/*        </button>*/}
                    {/*    </>*/}
                    {/*)}*/}
                </div>
            </div>
        </li>
    );
};

export default NoteSummary;