import React from "react";
import {Link} from "react-router-dom";
import "./Home.css"

const Home = () => {
    return (
        <div className="home-component">
            <div className="container">
                <div className="info-sec">
                    <h1>
                        <span className="text">Note Taker</span>
                        <span className="icon">
                            <svg aria-hidden="true"
                                 focusable="false"
                                 data-prefix="far"
                                 data-icon="sticky-note"
                                 className="svg-inline--fa fa-sticky-note fa-w-14" role="img"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path
                                fill="currentColor"
                                d="M448 348.106V80c0-26.51-21.49-48-48-48H48C21.49 32 0 53.49 0 80v351.988c0 26.51 21.49 48 48 48h268.118a48 48 0 0 0 33.941-14.059l83.882-83.882A48 48 0 0 0 448 348.106zm-128 80v-76.118h76.118L320 428.106zM400 80v223.988H296c-13.255 0-24 10.745-24 24v104H48V80h352z"></path>
                            </svg>
                        </span>
                    </h1>
                    <h4>Take Notes with MERN</h4>
                    <Link to={'/notes'} className="btn">Get Started</Link>
                </div>
            </div>
        </div>
    );
};

export default Home;

